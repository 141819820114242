
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Multiselect from '@vueform/multiselect';
  import * as Yup from 'yup';
  import { AdsPackageObject } from '@/store/modules/Subscriptions/AdsPackageModule';

  export default defineComponent({
    name: 'ads-package-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitServicesButton = ref<HTMLButtonElement | null>(null);
      const multiselect = ref<{ noOptions: boolean } | null>(null);
      const selectedServices = ref<Array<number>>([]);
      const loading = ref(false);
      const createdPackage = ref<AdsPackageObject | null>(null);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const packageSchema = Yup.object().shape({
        titleAr: Yup.string().required(() =>
          translate('AR_TITLE_IS_REQUIRED_FIELD')
        ),
        titleEn: Yup.string().required(() =>
          translate('EN_TITLE_IS_REQUIRED_FIELD')
        ),
        price: Yup.number().required(() =>
          translate('PRICE_IS_REQUIRED_FIELD')
        ),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.serviceIds = [];
        const result = await store.dispatch(Actions.CREATE_AD_PACKAGE, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          createdPackage.value = result;
          Swal.fire({
            text: translate('SUCCESSFULLY_ADDED_AD_Service'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
      };

      const onSubmitServices = async () => {
        if (submitServicesButton.value) {
          // eslint-disable-next-line
          submitServicesButton.value!.disabled = true;
          submitServicesButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          id: createdPackage.value?.id,
          data: { serviceIds: selectedServices.value },
        };
        await store.dispatch(Actions.UPDATE_ADS_PACKAGE, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitServicesButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitServicesButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_ADDED_AD_PACKAGE_SERVICES'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitServicesButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitServicesButton.value!.disabled = false;
          });
        }
      };
      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('adPackageAdsCreating'), [
          translate('subscriptions'),
        ]);
      });

      const multiSelectOpened = async () => {
        loading.value = true;
        if (multiselect.value?.noOptions) {
          await store.dispatch(Actions.GET_SERVICE_LIST, { limit: 50 });
        }
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        services: computed(() => store.getters.adsServicesList),
        submitServicesButton,
        onSubmitServices,
        onSubmitCreate,
        submitButton,
        translate,
        packageSchema,
        goBack,
        can,
        selectedServices,
        multiSelectLabel: 'title',
        loading,
        multiSelectOpened,
        multiselect,
        createdPackage,
      };
    },
  });
